<template>
  <Popup ref="popupRef" width="370px" :confirm-text="jobId ? '确认修改':'确认添加'" @confirm="confirm" @close="resetForm">
    <template #title>
      <div class="title-row">
        <div>定时{{type == 1 ? '置顶' : '推荐'}}设置</div>
        <div class="times">已执行：{{exeCnt}}小时</div>
      </div>
    </template>
    <div class="tips">
      设置定时{{type == 1 ? '置顶' : '推荐'}}后将按照指定的时间来自动对任务进行{{type == 1 ? '置顶' : '推荐'}}，需要确保账户余额充足，否则定时执行将会失败
    </div>
    <div class="label">配置名称：</div>
    <el-input v-model="form.name" type="text" placeholder="请输入配置名称"></el-input>
    <div class="label">开始时间：</div>
    <el-time-picker
      v-model="form.startTime"
      placeholder="请选择时间"
      value-format="HH:mm:ss">
    </el-time-picker>
    <div class="label">{{type == 1 ? '置顶' : '推荐'}}时长：</div>
    <el-input v-model="form.interval" type="number" placeholder="请输入时长"></el-input>
    <div class="label">
      <span>自动估价：</span>
      <span class="theme-text">单次消耗：{{unitPrice}}元/小时</span>
    </div>
    <el-input :value="unitPrice * form.interval" type="number" disabled></el-input>
    <div class="label">
      <div>是否重复执行：</div>
      <el-switch v-model="form.isRepeat" active-color="#FF7519" inactive-color="#B0B3BF"></el-switch>
    </div>
    <template v-if="form.isRepeat">
       <div class="label">
        <span>重复天数：</span>
      </div>
      <el-select v-model="form.repeatDay" placeholder="请选择">
        <el-option
          v-for="item in repeatDayOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
export default {
  components: {
    Popup
  },
  data() {
    return {
      form: {
        name: '',
        isRepeat: true,
        repeatDay: 0,
        startTime: '',
        endTime: '',
        interval: ''
      },
      repeatDayOptions: [],
      exeCnt: 0,
      jobId: '',
      type: 1, //1-置顶 2-推荐
    }
  },
  computed: {
    unitPrice() {
      const memberBenefit = this.$store.state.memberBenefit
      if(memberBenefit) {
        if(this.type == 1) return memberBenefit.topPrice
        if(this.type == 2) return memberBenefit.recommendPrice
      }
    }
  },
  created() {
    this.getRepeatDayOptions()
  },
  methods: {
    open(info) {
      if(info && info.type) this.type = info.type
      if(info && info.edit == true && info.detail) {
        this.form.name = info.detail.title
        this.form.isRepeat = info.detail.repeat
        this.form.repeatDay = info.detail.repeatDay
        this.form.startTime = info.detail.startTime
        this.form.endTime = info.detail.endTime
        this.form.interval = info.detail.interval
        this.exeCnt = info.detail.exeCnt
        this.jobId = info.detail.jobId
      }
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.form = {
        name: '',
        isRepeat: true,
        repeatDay: 0,
        startTime: '',
        endTime: '',
        interval: ''
      }
      this.refreshedTimes = 0
      this.jobId = ''
    },
    getRepeatDayOptions() {
      const repeatDayOptions = []
      for (let i = 0;i<30;i++) {
        if (i == '0') {
          repeatDayOptions.push({
            label: '每天',
            value: 0
          })
        } else {
          repeatDayOptions.push({
            label: i+'天',
            value: i
          })
        }
      }
      this.repeatDayOptions = repeatDayOptions
    },
    confirm() {
      if(!this.form.name.trim()) return this.$tips({message: '请输入配置名称', type: 'warning'})
      if(!this.form.startTime) return this.$tips({message: '请输入开始时间', type: 'warning'})
      if(!this.form.interval || this.form.interval <= 0) return this.$tips({message: '时长需大于0', type: 'warning'})
      const formData = {
        'interval': this.form.interval,
        'jobTitle': this.form.name,
        'repeatDay': this.form.isRepeat ? this.form.repeatDay : -1,
        'startTime': this.form.startTime
      }
      if(this.jobId) {
        Object.assign(formData, {jobId: this.jobId})
        this.$emit('confirm', formData, true)
      } else {
        this.$emit('confirm', formData)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
::v-deep .el-date-editor--time {
  width: 100%;
}
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  .times {
    font-size: 14px;
    font-weight: normal;
    color: var(--theme-color-red);
  }
}
.tips {
  padding: 8px 12px;
  background-color: #FFF6ED;
  color: var(--theme-color-yellow);
  border: 1px solid var(--theme-color-yellow);
  border-radius: 8px;
  font-size: 12px;
  line-height: 20px;
}
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 4px;
  margin-top: 24px;
  .warn {
    color: var(--theme-color-red);
    font-size: 12px;
  }
}
</style>