<template>
  <div class="auto">
    <ContentLayout>
      <template #left>
        <div class="panel">
          <div class="flex between-center mb16">
            <PanelTitle :title="title" :title-class="['fs20']" />
            <RulePopover :rule="autoRefreshRule"></RulePopover>
          </div>
          <div class="flex between-center mb8">
            <div>当前选择的任务：</div>
            <div v-if="currentTask" class="btn-rechoose" @click="$refs.totalPopupRef.open()">
              <span>重新选择</span>
              <img src="@/assets/images/arrow-right-theme.png">
            </div>
          </div>
         <CurrentTask :info="currentTask" @click.native="$refs.totalPopupRef.open()"></CurrentTask>
        </div>

        <!-- 配置列表 -->
        <div class="config">
          <div class="panel flex between-center" v-if="type == 0">
            <PanelTitle title="配置刷新" />
            <div class="flex items-center">
              <div class="fs16">
                剩余刷新次数：<span class="theme-text">{{ refreshTimes || 0 }}次</span>
              </div>
              <div class="btn-buy" @click="$refs.refreshModifyRef.open()">购买刷新</div>
            </div>
          </div>
          <div v-if="configList && configList.length > 0">
            <div
              class="panel flex between-center mt8"
              v-for="(item, index) in configList"
              :key="index"
            >
              <div>
                <div class="flex items-center">
                  <div class="name">{{ item.jobTitle }}</div>
                  <div :class="[item.status == 0 ? 'tag-theme' : 'tag-green']" v-if="item.statusDesc">
                    {{ item.statusDesc }}
                  </div>
                </div>
                <div class="desc" v-if="item.subTitle">{{ item.subTitle }}</div>
              </div>
              <div class="flex items-center">
                <div class="btn-edit" @click="editConfig(item)">
                  <img src="@/assets/images/icon-edit.png" />
                  <span>修改配置</span>
                </div>
                <div class="btn-play btn-fill-green" v-if="item.status == 0" @click="handleAction(1, item.jobId)">
                  <img src="@/assets/images/icon-play.png" />
                  <span>{{ item.buttonText || "开始执行" }}</span>
                </div>
                <div class="btn-pause btn-fill-yellow" v-if="item.status == 1" @click="handleAction(2, item.jobId)">
                  <img src="@/assets/images/icon-pause.png" />
                  <span>{{ item.buttonText || "停止执行" }}</span>
                </div>
              </div>
            </div>
            <!-- 添加配置 -->
            <div class="add-row panel" @click="addConfig">
              <img src="@/assets/images/add-fill.png" />
              <span>添加配置</span>
            </div>
          </div>
          <div class="nothing panel" v-else>
            <Empty tips="暂无配置" />
            <div class="btn-add btn-fill-theme" @click="addConfig">
              <img src="@/assets/images/add-fill-white.png" />
              <div>添加配置</div>
            </div>
          </div>
        </div>
      </template>

      <template #right>
        <PromoteSection class="mb12" />
        <Asset></Asset>
      </template>
    </ContentLayout>
    <RefreshPopup ref="refreshPopupRef" @confirm="settingConfig"/>
    <RecomOrTopPopup ref="recomOrTopPopupRef" @confirm="settingConfig"/>
     <!-- 全部任务弹窗 -->
    <TaskListTotalPopup ref="totalPopupRef" :list="pubTaskList" 
    @confirm="chooseTask" @loadmore="getPubTaskList($event.page)"></TaskListTotalPopup>
    <!-- 修改/刷新弹窗 -->
    <RefreshAndModify ref="refreshModifyRef"></RefreshAndModify>
  </div>
</template>

<script>
import CurrentTask from "@/components/CurrentTask";
import PanelTitle from "@/components/PanelTitle";
import ContentLayout from "@/components/ContentLayout";
import PromoteSection from "@/components/PromoteSection";
import Empty from "@/components/Empty";
import Asset from "@/components/Asset";
import RulePopover from '@/components/RulePopover'
import RefreshPopup from './components/RefreshPopup.vue'
import RecomOrTopPopup from './components/RecomOrTopPopup.vue'
import TaskListTotalPopup from '@/components/TaskListTotalPopup'
import RefreshAndModify from '@/components/RefreshAndModify'
import { changeRefreshApi } from "@/api/user.js";
import { taskJobsApi, pubTasksApi, operateTaskJobApi, addTaskJobApi, modifyTaskJobApi, queryTaskJobApi } from "@/api/task.js";
export default {
  name: "Auto",
  components: {
    ContentLayout,
    PanelTitle,
    CurrentTask,
    PromoteSection,
    Asset,
    Empty,
    RulePopover,
    TaskListTotalPopup,
    RefreshAndModify,
    RefreshPopup,
    RecomOrTopPopup
  },
  data() {
    return {
      taskId: "",
      currentTask: null,
      type: 0, // 0刷新 1置顶 2推荐
      configList: [],
      refreshTimes: 0,
      pubTaskList: [], // 可选任务列表
      autoRefreshRule: ''
    };
  },
  computed: {
    title() {
      const map = {
        0: "自动刷新",
        1: "定时置顶",
        2: "定时推荐",
      };
      return map[this.type];
    },
  },
  created() {
    const query = this.$route.query
    this.taskId = query.taskId || "";
    this.type = query.type;
    if(query.taskDetail) this.currentTask = JSON.parse(decodeURIComponent(query.taskDetail || ''));
    this.getRefreshTimes();
    this.getPubTaskList()
    if(this.taskId) this.getConfigList();
  },
  methods: {
    getRefreshTimes() {
      changeRefreshApi({ needDescription: true })
        .then((res) => {
          console.log("刷新数据", res);
          this.refreshTimes = res.data.refreshTimes;
        })
        .catch((err) => {
          this.$tips({ message: err, type: "error" });
        });
    },
    getConfigList() {
      taskJobsApi({
        start: 0,
        taskId: this.taskId,
        type: this.type,
      }).then((res) => {
        console.log("配置列表", res);
        this.configList = res.data.taskJobs;
        this.autoRefreshRule = res.data.autoRefreshRule || ''
      })
      .catch((err) => {
        this.$tips({ message: err, type: "error" });
      });
    },
    getPubTaskList(pageNo = 1) {
      pubTasksApi({
        startPage: pageNo-1 < 0 ? 0 : pageNo-1,
        type: 0, // 0 已上架
      }).then((res) => {
        console.log("个人任务列表", res);
        const list = res.data.taskListItems || []
        if(pageNo == 1) {
          this.pubTaskList = list
        } else {
          this.pubTaskList = this.pubTaskList.concat(list)
          if(list.length == 0) this.$tips({message: '没有更多数据了', type: 'warning'})
        }
      }).catch((err) => {
        this.$tips({ message: err, type: "error" });
      });
    },
    chooseTask(e) {
      this.taskId = e.detail.taskId
      this.currentTask = e.detail
      this.getConfigList()
    },
    handleAction(act, jobId) {
      operateTaskJobApi({
        act,
        jobId,
        taskId: this.taskId,
        type: this.type
      }).then(res => {
        this.getConfigList()
        if(this.type == 0) this.getRefreshTimes()
        this.$tips({ message: '操作成功', type: "success" })
      }).catch((err) => {
        this.$tips({ message: err, type: "error" })
      })
    },
    addConfig() {
      if(!this.taskId) return this.$tips({ message: '请选择一个任务', type: "warning" })
      if(this.type == 0) {
        this.$refs.refreshPopupRef.open()
      } else {
        this.$refs.recomOrTopPopupRef.open({type: this.type})
      }
    },
    editConfig(info) {
      queryTaskJobApi({
        'act': 0,
        'jobId': info.jobId,
        'taskId': this.taskId,
        'type': this.type
      }).then(res => {
        if(this.type == 0) {
          this.$refs.refreshPopupRef.open({edit: true, detail: res.data})
        } else {
          this.$refs.recomOrTopPopupRef.open({edit: true, detail: res.data, type: this.type})
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
      
    },
    async settingConfig(formData, isEdit) {
      try {
        Object.assign(formData, {type: this.type, taskId: this.taskId})
        console.log('formData', formData)
        let res
        if(isEdit) {
          res = await modifyTaskJobApi(formData)
        } else {
          res = await addTaskJobApi(formData)
        }
        if(this.type == 0) {
          this.$refs.refreshPopupRef.close()
        } else {
          this.$refs.recomOrTopPopupRef.close()
        }
        this.$tips({message: res.msg, type: 'success'})
        this.getConfigList()
        this.$store.dispatch('GET_USER_INFO') // 更新余额
      } catch (err) {
        this.$tips({message: err, type: 'error'})
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.btn-rechoose {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--theme-color);
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
}
.config {
  margin-top: 16px;
  .btn-buy {
    height: 30px;
    line-height: 30px;
    padding: 0 16px;
    border-radius: 8px;
    background-color: var(--theme-color);
    color: #fff;
    margin-left: 16px;
    cursor: pointer;
  }
  .name {
    font-size: 16px;
    font-weight: bold;
  }

  .desc {
    font-size: 12px;
    color: #65666f;
    margin-top: 4px;
  }
  .tag-theme,
  .tag-green {
    padding: 0 4px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 10px;
    margin-left: 12px;
  }
  .tag-theme {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
  }
  .tag-green {
    border: 1px solid var(--theme-color-green);
    color: var(--theme-color-green);
  }
  .btn-edit,
  .btn-play,
  .btn-pause {
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 8px;
    height: 38px;
    cursor: pointer;
    margin-left: 12px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
  .btn-edit {
    border: 1px solid #e8e8e8;
  }
}

.add-row {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-top: 8px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }
}
.nothing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 358px;
  margin-top: 8px;
  .btn-add {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 36px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 12px;
    margin-top: 12px;
    cursor: pointer;
    img {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }
  }
}
</style>