<template>
  <Popup ref="popupRef" width="370px" :confirm-text="jobId ? '确认修改':'确认添加'" @confirm="confirm" @close="resetForm">
    <template #title>
      <div class="title-row">
        <div>自动刷新设置</div>
        <div class="times">已刷新：{{refreshedTimes}}次</div>
      </div>
    </template>
    <div class="tips">
      设置自动刷新后按照刷新频率定时刷新你的任务排名，最后刷新的排在前面
    </div>
    <div class="label">
      <span>刷新配置名称：</span>
    </div>
    <el-input v-model="form.name" type="text" placeholder="请输入配置名称"></el-input>
    <div class="label">
      <div>是否重复刷新：</div>
      <el-switch v-model="form.isRepeat" active-color="#FF7519" inactive-color="#B0B3BF"></el-switch>
    </div>
    <template v-if="form.isRepeat">
       <div class="label">
        <span>重复天数：</span>
      </div>
      <el-select v-model="form.repeatDay" placeholder="请选择">
        <el-option
          v-for="item in repeatDayOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </template>
    <div class="label">
      <span>刷新起止时间：</span>
      <span class="warn">不设置时间段则默认全天</span>
    </div>
    <div class="flex items-center">
      <el-time-picker
        v-model="form.startTime"
        placeholder="开始时间"
        value-format="HH:mm:ss">
      </el-time-picker>
      <div style="width: 6px"></div>
      <el-time-picker
        v-model="form.endTime"
        placeholder="结束时间"
        value-format="HH:mm:ss">
      </el-time-picker>
    </div>
    <div class="label">刷新周期：</div>
    <el-select v-model="form.interval" placeholder="请选择">
      <el-option
        v-for="item in intervalOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
export default {
  components: {
    Popup
  },
  data() {
    return {
      form: {
        name: '',
        isRepeat: true,
        repeatDay: 0,
        startTime: '',
        endTime: '',
        interval: ''
      },
      intervalOptions: [{label: '30秒', value: 30},{label:'1分钟', value: 60},{label:'3分钟', value: 180},{label: '5分钟', value: 300},{label:'10分钟', value: 600},{label:'15分钟', value: 900},{label: '20分钟', value: 1200},
      {label:'30分钟',value: 1800},{label:'50分钟', value: 3000},{label: '1小时',value: 3600}],
      repeatDayOptions: [],
      refreshedTimes: 0,
      jobId: ''
    }
  },
  created() {
    this.getRepeatDayOptions()
  },
  methods: {
    open(info) {
      if(info && info.edit == true && info.detail) {
        this.form.name = info.detail.title
        this.form.isRepeat = info.detail.repeat
        this.form.repeatDay = info.detail.repeatDay
        this.form.startTime = info.detail.startTime
        this.form.endTime = info.detail.endTime
        this.form.interval = info.detail.interval
        this.refreshedTimes = info.detail.refreshedTimes
        this.jobId = info.detail.jobId
      }
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.form = {
        name: '',
        isRepeat: true,
        repeatDay: 0,
        startTime: '',
        endTime: '',
        interval: ''
      }
      this.refreshedTimes = 0
      this.jobId = ''
    },
    getRepeatDayOptions() {
      const repeatDayOptions = []
      for (let i = 0;i<30;i++) {
        if (i == '0') {
          repeatDayOptions.push({
            label: '每天',
            value: 0
          })
        } else {
          repeatDayOptions.push({
            label: i+'天',
            value: i
          })
        }
      }
      this.repeatDayOptions = repeatDayOptions
    },
    confirm() {
      if(!this.form.name.trim()) return this.$tips({message: '请输入配置名称', type: 'warning'})
      if(!this.form.startTime) return this.$tips({message: '请输入开始时间', type: 'warning'})
      if(!this.form.endTime) return this.$tips({message: '请输入结束时间', type: 'warning'})
      if(!this.form.interval || this.form.interval <= 0) return this.$tips({message: '请选择刷新周期', type: 'warning'})
      const formData = {
        'endTime': this.form.endTime,
        'interval': this.form.interval,
        'jobTitle': this.form.name,
        'repeatDay': this.form.isRepeat ? this.form.repeatDay : -1,
        'startTime': this.form.startTime
      }
      if(this.jobId) {
        Object.assign(formData, {jobId: this.jobId})
        this.$emit('confirm', formData, true)
      } else {
        this.$emit('confirm', formData)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  .times {
    font-size: 14px;
    font-weight: normal;
    color: var(--theme-color-red);
  }
}
.tips {
  padding: 8px 12px;
  background-color: #FFF6ED;
  color: var(--theme-color-yellow);
  border: 1px solid var(--theme-color-yellow);
  border-radius: 8px;
  font-size: 12px;
  line-height: 20px;
}
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 8px;
  margin-top: 24px;
  .warn {
    color: var(--theme-color-red);
    font-size: 12px;
  }
}
</style>